
import PerfilService from "../../../Services/PerfilService";
export default {
  name: "perfil-detalle",
  components: {
    ItemSeccionPermiso: () => import("./componentes/ItemSeccionPermiso.vue"),
    SeccionEditarPermiso: () =>
      import("./componentes/SeccionEditarPermiso.vue"),
  },
  data() {
    return {
      breadcrumbs: [
        { etiqueta: "Configuración" },
        { etiqueta: "Perfiles", to: { name: "perfilesGestor" } },
        { etiqueta: "Detalle" },
      ],
      perfilId: this.$route.params.id,
      perfilObj: {},
      permisos: [],
      opcionesToolbar: [],
      columnasPerfiles: [
        {
          label: "Nombre",
          name: "titulo",
          field: "titulo",
          align: "left",
          headerStyle: "width: 20%",
        },
        {
          label: "Descripción",
          name: "descripcion",
          field: "descripcion",
          align: "left",
          headerStyle: "width: 60%",
        },
        {
          label: "Registro",
          name: "registroFecha",
          field: "registro_fecha",
          align: "left",
          headerStyle: "width: 20%",
          format: (val) => this.formatearFecha(val, 2, true),
        },
      ],
      // Edicion general
      modalEditar: false,
      editarPerfilObj: {
        nombre: "",
        descripcion: "",
      },
      // Editar permisos
      mostrarEdicionPermisos: false,
      permisosOpciones: [],
      // Eliminar
      modalEliminar: false,
    };
  },
  mounted() {
    this.cargaInicial();
  },
  methods: {
    async cargaInicial() {
      this.loader(true);
      await this.obtenerDetalle(false);
      this.loader(false);
    },
    async obtenerDetalle(loader = true) {
      if (loader) this.loader(true);
      let datos = {
        perfilId: this.perfilId,
      };
      await PerfilService.obtenerDetalle(datos)
        .then((resp) => {
          this.perfilObj = resp.info;
          this.permisos = resp.permisos;

          this.llenarOpcionesToolbar();
        })
        .catch((error) => this.mostrarAlerta(error, "error"))
        .then(() => {
          if (loader) this.loader(false);
        });
    },
    async obtenerPermisos(loader = true) {
      if (loader) this.loader(true);
      await PerfilService.obtenerPermisos({ ordenar: "titulo_asc" })
        .then((resp) => {
          this.permisosOpciones = resp;
        })
        .catch((error) => this.mostrarAlerta(error, "error"))
        .then(() => {
          if (loader) this.loader(false);
        });
    },
    async editarPerfil(loader = true) {
      if (loader) this.loader(true);
      let datos = {
        ...this.editarPerfilObj,
        perfilId: this.perfilId,
      };
      await PerfilService.editar(datos)
        .then(async (resp) => {
          this.cerrarModalEditar();
          await this.obtenerDetalle(false);
          this.mostrarAlerta(resp.mensaje, "exito");
        })
        .catch((error) => this.mostrarAlerta(error, "error"))
        .then(() => {
          if (loader) this.loader(false);
        });
    },
    async editarPermisos(permisos, loader = true) {
      if (loader) this.loader(true);
      let nuevosPermisos = permisos.map((p) => p.permisosSeleccionados).flat();
      let permisosIds = nuevosPermisos.map((np) => np.permiso_id);
      let datos = {
        perfilId: this.perfilId,
        permisosNuevos: JSON.stringify(permisosIds),
      };
      await PerfilService.editarPermisos(datos)
        .then(async (resp) => {
          await this.obtenerDetalle(false);
          this.ocultarCardEditarPermisos();
          this.mostrarAlerta(resp.mensaje, "exito");
        })
        .catch((error) => this.mostrarAlerta(error, "error"))
        .then(() => {
          if (loader) this.loader(false);
        });
    },
    async eliminarPerfil(loader = true) {
      if (loader) this.loader(true);
      let datos = {
        perfilId: this.perfilId,
      };
      await PerfilService.eliminar(datos)
        .then(async (resp) => {
          this.cerrarModalEliminar();
          await this.obtenerDetalle(false);
          this.mostrarAlerta(resp.mensaje, "exito");
        })
        .catch((error) => this.mostrarAlerta(error, "error"))
        .then(() => {
          if (loader) this.loader(false);
        });
    },
    llenarOpcionesToolbar() {
      this.opcionesToolbar = [
        {
          texto: "Editar",
          icono: "editar",
          accion: "abrirModalEditar",
          disabled: this.perfilObj.status != 200,
        },
        {
          texto: "Eliminar",
          icono: "eliminar",
          accion: "abrirModalEliminar",
          disabled: this.perfilObj.status != 200,
        },
      ];
    },
    abrirModalEditar() {
      this.editarPerfilObj = _.cloneDeep(this.perfilObj);
      this.modalEditar = true;
    },
    cerrarModalEditar() {
      this.editarPerfilObj = {
        nombre: "",
        descripcion: "",
      };
      this.modalEditar = false;
    },
    abrirModalEliminar() {
      this.modalEliminar = true;
    },
    cerrarModalEliminar() {
      this.modalEliminar = false;
    },
    async mostrarCardEditarPermisos() {
      // Se obtienen todos los permisos
      if (this._.isEmpty(this.permisosOpciones)) await this.obtenerPermisos();
      this.mostrarEdicionPermisos = true;
    },
    ocultarCardEditarPermisos() {
      this.mostrarEdicionPermisos = false;
    },
  },
};
